import { Component, HostListener, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.css']
})
export class SearchbarComponent implements OnInit {
  @Input() placeholder: string;
  @Input() searchText: string;
  @Output() onTextChange: EventEmitter<string> = new EventEmitter<string>();


  constructor() { }

  ngOnInit(): void { }

  TextChange() {
    this.onTextChange.emit(this.searchText)
  }

}
