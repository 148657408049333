import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { sharedHeaders } from '../shared/constants';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  private baseUrl = environment.apiUrl;

  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.userToken;
    const jwt = this.authService.currentUser?.jwt;

    const urlNeedAuth = request.url.includes(this.baseUrl);
    const requestHeaders: {[key: string]: string} = jwt && urlNeedAuth ? { Authorization: `Bearer ${jwt}` } : {};
    requestHeaders[sharedHeaders.userToken] = token;

    request = request.clone({
      setHeaders: requestHeaders
    });

    return next.handle(request);
  }
}
