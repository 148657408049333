<app-page-header></app-page-header>
<div class="row">
    <div class="col col-md-11">
    </div>
    <div class="col col-md-1">
        <a class="back-link" [routerLink]="['/login']">Back</a>
    </div>
</div>
<div id="formContainer">
    <form #resetPasswordForm="ngForm" (ngSubmit)="resetPassword(resetPasswordForm)">
        <div class="form-group">
            <label for="password">New password</label>
            <input type="password" ngModel name="password" placeholder="Password" class="form-control" required
                #password="ngModel" pattern="(?=.*\d+)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*~]).{8,}" />
            <div *ngIf="password.invalid && password.touched" class="alert alert-danger">
                <div *ngIf="password.errors.required"> Password is required. </div>
                <div *ngIf="password.errors.pattern"> Password must be at least 8 characters long. <br> It must contain
                    at least one uppercase letter, <br> one lowercase letter, one special character <br> and one number.
                </div>
            </div>
        </div>
        <div class="form-group">
            <label for="confirmPassword">Confirm password</label>
            <input type="password" ngModel name="confirmPassword" placeholder="Confirm Password" class="form-control"
                required #confirmPassword="ngModel" />
            <div *ngIf="confirmPassword.invalid && confirmPassword.touched" class="alert alert-danger">
                <div *ngIf="confirmPassword.errors.required"> Confirm password is required. </div>
            </div>
            <div *ngIf="(password.value !== confirmPassword.value) && confirmPassword.touched"
                class="alert alert-danger">
                <div> Password & Confirm Password do not match. </div>
            </div>
        </div>
        <button type="submit" class="btn btn-primary"
            [disabled]="!resetPasswordForm.form.valid || password.value !== confirmPassword.value">Submit</button>
    </form>
</div>