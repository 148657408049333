(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("knockout"), require("survey-knockout"));
	else if(typeof define === 'function' && define.amd)
		define("SurveyCreator", ["knockout", "survey-knockout"], factory);
	else if(typeof exports === 'object')
		exports["SurveyCreator"] = factory(require("knockout"), require("survey-knockout"));
	else
		root["SurveyCreator"] = factory(root["ko"], root["Survey"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_knockout__, __WEBPACK_EXTERNAL_MODULE_survey_knockout__) {
return 