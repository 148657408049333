import gql from 'graphql-tag';
import { FORM_LIST_INFO } from '../fragments/form.fragments';

export const GET_OWNED_FORMS_BY_USER = gql`
    query ($condition: JSON!) {
        forms(where: $condition) {
        ...formInfo
        }
    }
    ${FORM_LIST_INFO}
`;

export const GET_EDITABLE_FORMS_BY_USER = gql`
    query ($conditionEG: JSON, $conditionForm: JSON) {
        editingGroups(where: $conditionEG) {
        Name,
        Forms(where: $conditionForm) {
            ...formInfo
            }
        }
    }
    ${FORM_LIST_INFO}
`;

export const GET_VERSIONS_BY_FORM = gql`
    query ($condition: JSON) {
        forms(where: $condition) {
            id,
            Name,
            FormIdentifier,
            FormVersion
        }
    }
`;
