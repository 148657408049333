<div #modalBackdrop class='modal-backdrop'>
    <form #createFormForm #form='ngForm' (ngSubmit)='createForm(form)'
        class='fixed w-140 h-80 bg-white shadow-modal rounded-md' novalidate>
        <div class='h-20 bg-teal-500 rounded-md'>
            <div class='text-gray-100 text-4xl font-brand font-bold leading-10 absolute left-32 top-5'>
                {{ textValues.createFormModalHeader }}
            </div>
        </div>
        <div
            class='flex items-center justify-between w-124 px-2 bg-white border border-gray-100 rounded space-x-4 h-20 absolute top-32 left-8'>
            <img src='/assets/add-form-name.svg' class='order-first mt-2' />
            <input type='text' name='formName' ngModel required class='input-field'
                [placeholder]='textValues.createFormInputPlaceholder' />
        </div>
        <div class='flex justify-end space-x-4 mt-40 mr-7'>
            <button type='submit'
                class='px-6 py-3 text-sm font-bold leading-4 text-white whitespace-nowrap teal-button'>
                {{ textValues.createFormSubmitBtnTxt }}
            </button>
            <button type='button' (click)='closeBtnClicked.emit()'
                class='px-6 py-3 text-sm font-bold leading-4 text-white whitespace-nowrap bg-gray-700 rounded-md shadow-grey-button'>
                {{ textValues.closeModalBtnText }}
            </button>
        </div>
    </form>
</div>