import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { TextValues } from 'src/app/shared/text-values';
import Utils from 'src/app/shared/utils';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {
  userEmail: string;
  resetPasswordConfirmationShown = false;

  constructor(private authService: AuthService, private location: Location, public textValues: TextValues) { }

  requestLinkForPasswordReset(form: NgForm): void {
    this.userEmail = form.value.email;
    this.authService.forgotPassword(this.userEmail).subscribe(_ => this.resetPasswordConfirmationShown = true, error => alert(Utils.extractErrorMessage(error.errorResponse)));
  }

  closeConfirmationModal() {
    this.resetPasswordConfirmationShown = false;
  }

  goBack(): void {
    this.location.back();
  }
}
