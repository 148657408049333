<app-create-form *ngIf="createFormModalOpened" (closeBtnClicked)="closeCreateFormModal()"></app-create-form>
<app-share-form *ngIf="shareFormModalOpened" [shareLink]="pickedShareFormLink" (closeBtnClicked)="closeShareFormModal()"></app-share-form>
<app-form-settings *ngIf="formSettingsModalOpened" [form]="pickedForm" [simpleDisplay]="false" (closeModal)="closeFormSettingsModal()" (saveChanges)="updateFormSettings($event)"> </app-form-settings>
<div class="flex bg-gray-100 min-h-screen h-full">
    <navbar (createFormBtnClicked)="openCreateFormModal($event)"></navbar>
    <div [ngClass]="{'page-container-navbar-collapsed': layoutService.navbarCollapsed,
    'page-container-navbar-not-collapsed': !layoutService.navbarCollapsed}" class="w-full">
        <app-page-header></app-page-header>
        <div class="flex p-8 overflow-x-auto md:flex-row">
            <div class="flex w-2/3 text-gray-700 space-x-12 order-none">
                <h2 class="text-4xl font-bold leading-10">
                    {{ textValues.formListHeader }}
                </h2>
            </div>
            <div class="flex w-1/3 space-x-10 justify-end z-50">
              <app-dropdown [openDirection]="'down'" class='z-50 absolute right-72'>
                <ng-container button>
                    <button class='flex w-40 items-center btn-basic btn-light justify-between px-3 bg-white border-2 border-gray-200 rounded h-11 text-sm'>Items per page
                    <img class='-mr-1 ml-2 h-5 w-5' src='/assets/down-arrow.svg'/>
                    </button>
                </ng-container>
                <ng-container content>
                    <option class='dropdown-element' [ngClass]="{ 'active' : itemsPerPage == config.tenItems }"  (click)="changeItemsPerPage(config.tenItems)">{{config.tenItems}}</option>
                    <option class='dropdown-element' [ngClass]="{ 'active' : itemsPerPage == config.twentyFiveItems }" (click)="changeItemsPerPage(config.twentyFiveItems)">{{config.twentyFiveItems}}</option>
                    <option class='dropdown-element' [ngClass]="{ 'active' : itemsPerPage == config.fiftyItems }" (click)="changeItemsPerPage(config.fiftyItems)">{{config.fiftyItems}}</option>
                    <option class='dropdown-element' [ngClass]="{ 'active' : itemsPerPage == config.hundredItems }" (click)="changeItemsPerPage(config.hundredItems)">{{config.hundredItems}}</option>
                </ng-container>
            </app-dropdown>
            <app-searchbar class='w-60' (onTextChange)='onTextChange($event)' [searchText]='searchText' autocomplete="off"
            [placeholder]='textValues.formSearchPlaceholder'></app-searchbar>
            </div>
        </div>
        <div class="flex">
            <div class="flex w-full lg:px-2 xl:px-1 2xl:px-8 border-gray-200">
                <table class="w-full bg-white divide-y divide-gray-200">
                    <thead>
                        <tr>
                            <th class="text-left table-header cursor-pointer" (click)="sort(['Name'])">
                                <div class="flex flex-row items-center">
                                    <p>
                                        {{ textValues.nameFormListColHeader }}
                                    </p>
                                    <img src="../../../assets/icons8-up-arrow-50.svg" *ngIf="sortDirection == 'asc'" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor" />
                                    <img src="../../../assets/icons8-down-arrow-50.svg" *ngIf="sortDirection == 'desc'" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor" />
                                </div>
                            </th>
                            <th class="text-center table-header">
                                {{ textValues.submissionsFormListColHeader }}
                            </th>
                            <th class="text-center table-header cursor-pointer" (click)="sort(['PublishDate'])">
                                <div class="flex flex-row justify-center items-center">
                                    <p>
                                        {{ textValues.datePublishedFormListColHeader }}
                                    </p>
                                    <img src="../../../assets/icons8-up-arrow-50.svg" *ngIf="sortDirection == 'asc'" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor" />
                                    <img src="../../../assets//icons8-down-arrow-50.svg" *ngIf="sortDirection == 'desc'" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor" />
                                </div>
                            </th>
                            <th class="text-center table-header">
                                {{ textValues.statusFormListColHeader }}
                            </th>

                        </tr>
                    </thead>
                    <tbody *ngIf='forms?.length > 0' class='divide-y divide-gray-200'>
                        <tr *ngFor='let form of forms | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage } |searchFilter:searchText:["Name", "PublishDate"]'>
                            <td class='py-4'>
                                <div class='px-4 text-left table-content xl:w-40 2xl:w-60'>
                                    {{ form.Name }}
                                </div>
                            </td>
                            <td class='py-4'>
                                <div class='text-center table-content'>
                                    {{ form.FormVersion }}
                                </div>
                            </td>
                            <td class='py-4'>
                                <div class='text-center table-content'>
                                    {{ form.SubmissionsCount }}
                                </div>
                            </td>
                            <td class="py-4">
                                <div class="text-center table-content">
                                    {{ form.PublishDate | date: "MM.dd.yy" }}
                                </div>
                            </td>
                            <td class='px-2 py-4'>
                                <span class='form-status' [class.form-status-published]='form.IsPublished'
                                    [class.form-status-unpublished]='!form.IsPublished'>
                                    {{ form.IsPublished ? textValues.publishedFormStatusVal :
                                    textValues.unpublishedFormStatusVal }} ({{'v' + form.FormVersion}})
                                </span>
                            </td>
                            <td class="hidden xl:table-cell px-4 py-4 xl:px-2 2xl:px-4">
                                <a class="table-button" [routerLink]="['/forms', form.FormIdentifier, 'versions', form.FormVersion]">
                                    {{ textValues.formListEditBtnTxt }}
                                </a>
                            </td>
                            <td class="hidden xl:table-cell px-4 py-4 xl:px-2 2xl:px-4">
                                <button class="table-button w-full" (click)="openShareFormModal($event, form)">
                                    {{ textValues.formListShareBtnTxt }}
                                </button>
                            </td>
                            <td class="hidden xl:table-cell px-4 py-4 xl:px-2 2xl:px-4">
                                <a class="table-button" [routerLink]="['/forms', form.FormIdentifier, 'answers']">
                                    {{ textValues.formListAnswersBtnTxt }}
                                </a>
                            </td>
                            <td class="px-4 py-4 xl:px-2 2xl:px-4">
                                <app-dropdown classes="relative three-dots table-button">
                                    <div button>
                                        <img src="/assets/more.svg" class="p-5" />
                                    </div>
                                    <ng-container content>
                                        <a class="dropdown-element mt-4 block xl:hidden" [routerLink]="['/forms', form.FormIdentifier, 'versions', form.FormVersion]">
                                            {{ textValues.formListEditBtnTxt }}
                                        </a>
                                        <a class="dropdown-element block xl:hidden" (click)="openShareFormModal($event, form)">
                                            {{ textValues.formListShareBtnTxt }}
                                        </a>
                                        <a class="dropdown-element block xl:hidden" [routerLink]="['/forms', form.FormIdentifier, 'answers']">
                                            {{ textValues.formListAnswersBtnTxt }}
                                        </a>
                                        <a (click)="openFormSettingsModal(form)" class="dropdown-element block mt-0 xl:mt-4">
                                            Form Settings
                                        </a>
                                        <a [routerLink]="['/forms', form.FormIdentifier, 'datatable']" class="dropdown-element block" >
                                            Data Table
                                        </a>
                                        <a [routerLink]="['/forms', form.FormIdentifier, 'diagrams']" class="dropdown-element block">Diagrams</a>
                                        <a (click)="deleteForm(form)" class="dropdown-element block mb-4">Delete Form</a>
                                    </ng-container>
                                </app-dropdown>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div *ngIf="forms?.length > itemsPerPage" class="flex justify-center items-center my-5">
            <pagination-controls class="pagination-ctrl" (pageChange)="navigateToPage($event)"></pagination-controls>
        </div>
    </div>
</div>
