<div class='flex bg-gray-100'>
    <app-create-form *ngIf='createFormModalOpened' (closeBtnClicked)='closeCreateFormModal()'></app-create-form>
    <navbar></navbar>
    <app-form-settings class='z-50' *ngIf='formSettingsModalOpened' [form]='form' [simpleDisplay]=true (closeModal)='closeFormSettingsModal()' (saveChanges)='updateFormSettings()'></app-form-settings>
    <div [ngClass]="{'page-container-navbar-collapsed': layoutService.navbarCollapsed, 'page-container-navbar-not-collapsed': !layoutService.navbarCollapsed}" class='w-full h-screen' class='w-full'>
        <app-page-header></app-page-header>
        <div [ngClass]="{'flex h-full justify-center': !this.form || this.savingForm}">
            <app-spinner *ngIf="!this.form || this.savingForm" class="flex self-center"></app-spinner>
            <div *ngIf='form'>
                <div class='flex px-10 mt-5 items-center'>
                    <div class='w-3/4 text-4xl font-bold leading-10 text-gray-700'>
                        {{ form.Name }}
                    </div>
                    <div class='w-1/4 flex justify-end'>
                        <button [routerLink]="['/forms']" class='w-20 h-10 px-6 py-3 text-sm font-bold leading-4 text-white whitespace-nowrap teal-button'>{{ textValues.backBtnTxt }}</button>
                    </div>
                </div>
                <div class='flex mt-5 px-10 space-x-6'>
                    <button *ngIf='form.IsPublished' class='w-28 btn-basic btn-dark' (click)='onUnpublishFormVersion()'>{{ textValues.publishedFormStatusVal }}</button>
                    <button *ngIf='!form.IsPublished' class='w-32 btn-basic btn-light' (click)='onPublishFormVersion()'>{{ textValues.unpublishedFormStatusVal }}</button>
                    <div class='flex'>
                        <app-dropdown [openDirection]="'down'">
                            <ng-container button>
                                <button class='flex w-32 justify-center items-center btn-basic btn-light'>Version {{ form.FormVersion }}
                                    <img class='-mr-1 ml-2 h-5 w-5' src='/assets/down-arrow.svg' />
                                </button>
                            </ng-container>
                            <ng-container content>
                                <a *ngFor="let version of allVersions" [ngClass]="{ 'active' : form.id === version.id }" class='dropdown-element'
                                [routerLink]="['/forms', version.FormIdentifier, 'versions', version.FormVersion]">
                                    Version {{ version.FormVersion }}
                                </a>
                            </ng-container>
                        </app-dropdown>
                    </div>
                    <button class='w-28 btn-basic btn-light' (click)='openFormSettingsModal()'>{{ textValues.formSettingsBtnTxt }}</button>
                </div>
            </div>
            <div class='p-10' style='height:100vh'>
                <app-survey-creator *ngIf="form" [formJSON]='form.Definition' (saveFormTemplate)='onSaveFormTemplate($event)'></app-survey-creator>
            </div>
        </div>
    </div>
</div>
