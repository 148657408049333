import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import Utils from '../shared/utils';

@Injectable({
    providedIn: 'root'
})
export class HttpBaseService {
    baseUrl = environment.apiUrl;

    constructor(private http: HttpClient) { }

    get<T>(url: string): Observable<T> {
        return this.http.get<T>(`${this.baseUrl}${url}`).pipe(
            catchError(Utils.handleError)
        );
    }

    post<T>(url: string, body: any): Observable<T> {
        return this.http.post<T>(`${this.baseUrl}${url}`, body).pipe(
            catchError(Utils.handleError)
        );
    }

    put<T>(url: string, body: any, options?: { headers?: HttpHeaders | { [header: string]: string | string[]; } },
           useBaseUrl = true): Observable<T> {
        url = useBaseUrl ? `${this.baseUrl}${url}` : url;
        return this.http.put<T>(url, body, options).pipe(
            catchError(Utils.handleError)
        );
    }

    delete<T>(url: string): Observable<T> {
        return this.http.delete<T>(`${this.baseUrl}${url}`).pipe(
            catchError(Utils.handleError)
        );
    }
}
