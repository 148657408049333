import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  public navbarCollapsed = false;

  constructor() { }

  ngOnInit() {}
}
