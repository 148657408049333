<div *ngIf='!submission || savingSurveyAnswers' class='flex h-screen justify-center items-center'>
  <app-spinner></app-spinner>
</div>
<div *ngIf='submission && submission.canPreview' class='flex min-h-screen bg-gray-100'>
  <app-create-form *ngIf='createFormModalOpened' (closeBtnClicked)='closeCreateFormModal()'></app-create-form>
  <navbar (createFormBtnClicked)='openCreateFormModal()'></navbar>
  <div [ngClass]="{'page-container-navbar-collapsed': layoutService.navbarCollapsed, 'page-container-navbar-not-collapsed': !layoutService.navbarCollapsed}" class='w-full h-screen'>
    <app-page-header></app-page-header>
    <div class='flex px-10 mt-5 items-center'>
      <div class='w-3/4'>
        <div class='text-4xl font-bold leading-10 text-gray-700'>
          Previewing Form: {{ submission.Form.Name }}
        </div>
        <div class='text-2xl font-bold leading-10 text-gray-400'>
          Submitter: {{ submission.Submitter.username }}
        </div>
      </div>
      <div class='w-1/4 flex justify-end'>
        <button [routerLink]="['/submissions']"
          class='w-20 h-10 px-6 py-3 text-sm font-bold leading-4 text-white whitespace-nowrap teal-button'>
          {{ textValues.backBtnTxt }}
        </button>
      </div>
    </div>
    <div class="flex m-5 survey-container contentcontainer codecontainer">
      <app-survey class='w-full h-full' [submission]="submission"></app-survey>
    </div>
  </div>
</div>
<div class="relative z-10" *ngIf='submission && !submission.canPreview'>
  <div class="w-48 mb-5 absolute right-5 top-3 z-50">
    <user-profile-dropdown *ngIf="userIsLoggedIn"></user-profile-dropdown>
  </div>
  <div class="survey-container contentcontainer codecontainer">
    <app-survey class='w-full h-full' [submission]="submission"></app-survey>
  </div>
</div>