import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthGuard } from './auth.guard';
import { AuthService } from '../services/auth.service';
import { FormService } from '../services/form.service';
import { SubmissionService } from '../services/submission.service';

@Injectable({
  providedIn: 'root'
})
export class SubmissionGuard extends AuthGuard implements CanActivate {
  constructor(router: Router, authService: AuthService, private formService: FormService, private submissionService: SubmissionService) {
    super(router, authService);
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const formIdentifier = next.paramMap.get('formIdentifier');
    const subid = next.paramMap.get('submissionId');

    if (formIdentifier) {
      return this.formService.getPublishedFormByFormIdentifier(formIdentifier).pipe(
        map(form => {
          if (!form) {
            alert('Failed to get the requested Form.\nIt is probably not published or the requested Form Identifier does not exist.');
          }
          return form.AllowAnonymousSubmission || super.canActivate(next, state) as boolean;
        })
      );
    } else if (subid) {
      return this.submissionService.getSubmission(subid).pipe(
        map(sub => {
          if (sub) {
            return sub.Form.AllowAnonymousSubmission || super.canActivate(next, state) as boolean;
          } else {
            alert('Failed to get the requested Submission.');
          }
        })
      );
    }

    return of(false);
  }
}
