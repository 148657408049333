import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { SubmissionModel, SubmissionJSON } from '../models/submission.model';
import { GraphQLDataResponse } from '../interfaces/graphql-data-response';
import { HttpErrorMessage } from '../interfaces/http-error-message';
import { AwsS3object } from '../interfaces/aws-s3-object';
import { HttpBaseService } from './http-base.service';
import { AuthService } from './auth.service';
import * as submissionQueries from '../graphql/queries/submission.queries';
@Injectable({
  providedIn: 'root'
})

export class SubmissionService {

  constructor(private http: HttpBaseService, private apollo: Apollo, private authService: AuthService) { }

  getSubmission(submissionId: string): Observable<SubmissionModel> {
    return this.http.get<SubmissionJSON>(`/submissions/${submissionId}`).pipe(
      map(json => new SubmissionModel(json)),
      catchError((err: HttpErrorMessage) => {
        if (err.statusCode === 404) {
          return of(null);
        }
        return throwError(err);
      })
    );
  }

  getAllSubmissions(): Observable<SubmissionModel[]> {
    return this.http.get<SubmissionJSON[]>(`/submissions`).pipe(
      map(submissionArray =>
        submissionArray.map(json => new SubmissionModel(json))
      )
    );
  }

  getSubmissionsByForm(formIdentifier: string): Observable<SubmissionModel[]> {
    return this.http.get<SubmissionJSON[]>(`/submissions?Form.FormIdentifier=${formIdentifier}`).pipe(
      map(submissionArray =>
        submissionArray.map(json => new SubmissionModel(json))
      )
    );
  }

  getCompletedSubmissionsByForm(formIdentifier: string): Observable<SubmissionModel[]> {
    return this.http.get<SubmissionJSON[]>(`/submissions?Form.FormIdentifier=${formIdentifier}&IsDraft=false`).pipe(
      map(submissionArray =>
        submissionArray.map(json => new SubmissionModel(json))
      )
    );
  }

  getSubmissionsByUser(): Observable<ApolloQueryResult<GraphQLDataResponse>> {
    const submitterId = this.authService.currentUser.user.id;
    return this.apollo.query({
      query: submissionQueries.GET_SUBMISSIONS_BY_USER,
      variables: {
        condition: {
          Submitter: submitterId
        }
      }
    });
  }

  getDraftSubmissionForUser(formIdentifier: string): Observable<SubmissionModel> {
    return this.http.get<SubmissionJSON>(`/submissions/draft/form/${formIdentifier}`).pipe(
      map(json => new SubmissionModel(json)),
      catchError((err: HttpErrorMessage) => {
        if (err.statusCode === 404) {
          return of(null);
        }
        return throwError(err);
      })
    );
  }

  createSubmission(submission: SubmissionModel): Observable<SubmissionModel> {
    return this.http.post<SubmissionModel>(`/submissions`, submission);
  }

  updateSubmission(submissionId: string, submissionModel: SubmissionModel): Observable<SubmissionModel> {
    return this.http.put<SubmissionModel>(`/submissions/${submissionId}`, submissionModel);
  }

  completeSubmission(submissionId: string, submissionModel: SubmissionModel): Observable<SubmissionModel> {
    return this.http.put<SubmissionModel>(`/submissions/${submissionId}/complete`, submissionModel);
  }

  uploadFileToS3(file: File): Observable<string> {
    const contentType = file.type;
    const fileExtension = file.name.split('.').pop();
    const headers = {
      'Content-Type': file.type,
      'x-amz-acl': 'public-read',
    };

    return this.http.post<AwsS3object>(`/submissions/request-upload-url`, { contentType, fileExtension }).pipe(
      concatMap(result => this.http.put(result.uploadUrl, file, { headers }, false).pipe(
        map(_ => {
          const url = result.uploadUrl;
          return url.substring(0, url.indexOf('?'));
        })
      )),
      catchError(err => throwError(err))
    );
  }
}
