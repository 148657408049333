import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { GraphQLDataResponse } from '../interfaces/graphql-data-response';
import * as editingGroupQueries from '../graphql/queries/editing-group.queries';

@Injectable({
    providedIn: 'root'
})
export class EditingGroupService {
    constructor(private apollo: Apollo) { }

    getAllEditingGroups(): Observable<ApolloQueryResult<GraphQLDataResponse>> {
        return this.apollo.query({
            query: editingGroupQueries.GET_ALL_EDITING_GROUPS_NAMES
        });
    }
}
