import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult, throwServerError } from '@apollo/client/core';
import { FormModel } from '../models/form.model';
import { EditingGroupModel } from '../models/editing-group.model';
import { HttpErrorMessage } from '../interfaces/http-error-message';
import { GraphQLDataResponse } from '../interfaces/graphql-data-response';
import { HttpBaseService } from 'src/app/services/http-base.service';
import { AuthService } from './auth.service';
import * as formQueries from '../graphql/queries/form.queries';

@Injectable({
    providedIn: 'root'
})
export class FormService {
    constructor(private http: HttpBaseService, private authService: AuthService, private apollo: Apollo) { }

    getForm(formId: string): Observable<FormModel> {
        return this.http.get<FormModel>(`/forms/${formId}`);
    }

    getForms(): Observable<FormModel[]> {
        return this.http.get<FormModel[]>('/forms');
    }

    getFormsOwnedAndEditableByUser(): Observable<FormModel[]> {
        return this.http.get<FormModel[]>(`/forms/by-user`);
    }

    getVersionsByForm(formIdenfifier: string): Observable<ApolloQueryResult<GraphQLDataResponse>> {
        return this.apollo.query({
            query: formQueries.GET_VERSIONS_BY_FORM,
            variables: {
                condition: {
                    FormIdentifier: formIdenfifier
                }
            }
        });
    }

    getFormVersion(formIdenfifier: string, formVersion: number): Observable<FormModel> {
        return this.http.get<FormModel>(`/forms/${formIdenfifier}/versions/${formVersion}`);
    }

    getPublishedFormByFormIdentifier(formIdentifier: string): Observable<FormModel> {
        return this.http.get<FormModel>(`/forms/published/${formIdentifier}`).pipe(
            catchError((err: HttpErrorMessage) => {
                if (err.statusCode === 404) {
                    return of(null);
                }
                return throwError(err);
            })
        );
    }

    createForm(formModel: FormModel): Observable<FormModel> {
        return this.http.post<FormModel>(`/forms`, formModel);
    }

    createNewFormVersion(formId: string, newFormDefinition: JSON): Observable<FormModel> {
        return this.http.post<FormModel>(`/forms/${formId}/new-version`, newFormDefinition);
    }

    updateForm(formId: string, formModel: FormModel): Observable<FormModel> {
        return this.http.put<FormModel>(`/forms/${formId}`, formModel);
    }

    publishFormVersion(formId: string): Observable<FormModel> {
        return this.http.get<FormModel>(`/forms/${formId}/publish-version`);
    }

    unpublishFormVersion(formId: string): Observable<FormModel> {
        return this.http.get<FormModel>(`/forms/${formId}/unpublish-version`);
    }

    deleteForm(formIdentifier: string): Observable<FormModel> {
        return this.http.delete<FormModel>(`/forms/${formIdentifier}/all-versions`);
    }

    addEditingGroupToForm(formIdentifier: string, groupId: string): Observable<EditingGroupModel> {
        return this.http.get<EditingGroupModel>(`/forms/${formIdentifier}/add-editing-group/${groupId}`);
    }

    removeEditingGroupFromForm(formIdentifier: string, groupId: string): Observable<EditingGroupModel> {
        return this.http.get<EditingGroupModel>(`/forms/${formIdentifier}/remove-editing-group/${groupId}`);
    }
}
