<div class="relative">
    <button (click)="toggleDropdown()"
        class="flex items-center justify-between w-full bg-transparent hover:bg-gray-200 focus:outline-none">
        <div class="flex w-40 h-10">
            <div class="overflow-hidden text-left">
                <div class="text-base font-bold text-gray-800 truncate">
                    {{ username }}
                </div>
                <div class="text-xs font-bold text-gray-500">
                    {{ userRole }}
                </div>
            </div>
        </div>
        <img src="/assets/arrow-down.svg" />
    </button>
    <div *ngIf="opened" class="absolute right-0 w-full mt-2 origin-top-right rounded-md shadow-lg">
        <div class="px-2 py-2 bg-white rounded-md shadow">
            <a class="block px-4 py-2 mt-2 text-sm bg-transparent rounded-lg md:mt-0 hover:text-gray-900
                    focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:ring"
                href="#" (click)="logout()">
                {{ textValues.logoutText }}
            </a>
        </div>
    </div>
</div>
