import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormListComponent } from './components/form-list/form-list.component';
import { FormComponent } from './components/form/form.component';
import { SubmissionComponent } from './components/submission/submission.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ThankYouPageComponent } from './components/thank-you-page/thank-you-page.component';
import { SubmissionListComponent } from './components/submission-list/submission-list.component';
import { AnswersComponent } from './components/answers/answers.component';
import { AuthGuard } from './guards/auth.guard';
import { SubmissionGuard } from './guards/submission.guard';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SurveyAnalyticsComponent } from './components/survey-analytics/survey-analytics.component';
import { LoggedInGuard } from './guards/logged-in.guard';
import { ModeratorOnlyGuard } from './guards/moderator-only.guard';

const routes: Routes = [
  { path: 'login', canActivate: [LoggedInGuard], component: LoginComponent },
  { path: 'register', canActivate: [LoggedInGuard], component: RegisterComponent },
  { path: 'forgot-password', canActivate: [LoggedInGuard], component: ForgotPasswordComponent},
  { path: 'reset-password', canActivate: [LoggedInGuard], component: ResetPasswordComponent },
  { path: 'forms', canActivate: [AuthGuard, ModeratorOnlyGuard], children: [
    { path: '', component: FormListComponent },
    { path: ':formIdentifier/versions/:formVersion', component: FormComponent },
    { path: ':formIdentifier/answers', component: AnswersComponent },
    { path: ':formIdentifier/datatable', component: SurveyAnalyticsComponent },
    { path: ':formIdentifier/diagrams', component: SurveyAnalyticsComponent }
  ]},
  { path: 'forms/:formIdentifier/new-submission', canActivate: [SubmissionGuard], component: SubmissionComponent },
  { path: 'submissions', canActivate: [AuthGuard], component: SubmissionListComponent},
  { path: 'submissions/:submissionId', canActivate: [SubmissionGuard], component: SubmissionComponent },
  { path: 'submissions/:submissionId/thank-you', canActivate: [SubmissionGuard], component: ThankYouPageComponent },
  { path: '', redirectTo: 'forms', pathMatch: 'full' },
  { path: '**', redirectTo: 'forms', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
