import gql from 'graphql-tag';
import { SUBMISSION_INFO_IN_LIST } from '../fragments/submission.fragments';

export const GET_SUBMISSIONS_BY_USER = gql`
    query ($condition: JSON) {
        submissions(where: $condition) {
        ...submissionInfo
        }
    }
    ${SUBMISSION_INFO_IN_LIST}
`;
