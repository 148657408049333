<div class='flex h-screen'>
    <div class='w-full'>
        <img class='h-full p-28' src='/assets/inclusive-docs-logo-black.svg' />
    </div>
    <div class='w-124 px-9 2xl:px-20 bg-teal-500'>
        <div class='relative top-20'>
            <div class='flex absolute space-x-10 2xl:space-x-20 items-end'>
                <h1 [routerLink]="['/login']" class='text-3xl font-bold text-teal-300'>
                    {{ textValues.loginLinkTxt }}
                </h1>
                <a class='text-5xl font-bold text-gray-100 font-brand'>
                    {{ textValues.registerLinkTxt }}
                </a>
            </div>
            <form #userRegistrationForm='ngForm' (ngSubmit)='register(userRegistrationForm)' class='block relative top-24 space-y-10'>
                <div class='input-wrapper'>
                    <img class='mt-2' src='/assets/user-icon.svg' />
                    <input type='text' name='username' ngModel required placeholder='{{ textValues.registerUsernamePlaceholder }}' class='input-field' />
                </div>
                <div class='block'>
                    <div class='input-wrapper'>
                        <img *ngIf='!userEmailInput.errors?.email' class='mt-2' src='/assets/e-mail-teal.svg' />
                        <img *ngIf='userEmailInput.errors?.email' class='mt-2' src='/assets/e-mail-black.svg' />
                        <input #userEmailInput='ngModel' type='email' name='email' ngModel email required placeholder='{{ textValues.registerEmailPlaceholder }}' class='input-field' />
                    </div>
                    <div *ngIf='userEmailInput.errors?.email' class='text-white font-brand text-base font-bold rounded'>* E-mail not valid</div>
                </div>
                <div class='block'>
                    <div class='input-wrapper'>
                        <img *ngIf='!userPasswordInput.errors?.minlength' class='mt-2' src='/assets/lock-teal.svg' />
                        <img *ngIf='userPasswordInput.errors?.minlength' class='mt-2' src='/assets/lock-black.svg' />
                        <input #userPasswordInput='ngModel' type='password' name='password' ngModel required minlength='8' placeholder='{{ textValues.registerPasswordPlaceholder }}' class='input-field' />
                    </div>
                    <div *ngIf='userPasswordInput.errors?.minlength' class='text-white font-brand text-base font-bold rounded'>* Password must contain min 8 letters</div>
                </div>
                <div class='block'>
                    <div class='input-wrapper'>
                        <img *ngIf='!confirmPasswordInput.errors?.compare' class='mt-2' src='/assets/lock-teal.svg' />
                        <img *ngIf='confirmPasswordInput.errors?.compare' class='mt-2' src='/assets/lock-black.svg' />
                        <input #confirmPasswordInput='ngModel' name="confirmPassword" equalsToValidator comparer={{userPasswordInput.value}} type='password' ngModel required placeholder='{{ textValues.registerConfirmPassPlaceholder }}' class='input-field' />
                    </div>
                    <div *ngIf='(confirmPasswordInput.dirty || confirmPasswordInput.touched) && confirmPasswordInput.errors?.compare' class='text-white font-brand text-base font-bold rounded'>* Passwords don't match</div>
                </div>
                <div class='flex relative top-10 space-x-5 justify-end'>
                    <button type='submit' class='h-10 w-24 text-teal-500 font-bold bg-white rounded'>
                        {{ textValues.registerBtnTxt }}
                    </button>
                    <button class='h-10 w-40 text-gray-700 font-bold bg-white rounded' [routerLink]="['/forgot-password']">
                        {{ textValues.forgotPasswordBtnTxt }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
