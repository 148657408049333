import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, ValidatorFn } from '@angular/forms';

function equalsToValidator(valueToCompare: string): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    return control.value !== valueToCompare ? { compare: true } : null;
  };
}

@Directive({
  selector: '[equalsToValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: EqualsToValidatorDirective,
    multi: true
  }]
})
export class EqualsToValidatorDirective {
  @Input('comparer') comparer: string;

  constructor() { }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.comparer ? equalsToValidator(this.comparer)(control) : null;
  }

}
