<div *ngIf='!isCollapsed' class='h-screen bg-teal-500 z-30 lg:w-68 xl:w-72 2xl:w-80'>
    <div class='pt-5'>
        <img class='mx-auto' src='/assets/inclusive-docs-logo-white.svg' />
    </div>
    <div *ngIf='!userIsSubmitter' class="flex">
        <button class='create-form-btn' (click)='this.createFormBtnClicked.emit($event)'>
            <img class='inline-block' src='/assets/add-note.svg' />
            {{ textValues.createNewFormBtnTxt }}
        </button>
    </div>
    <div class='absolute -right-2'>
        <button type='button' (click)='toggleNavbarCollapse()'>
            <div class='px-1 rounded-full bg-white text-gray-500 text-xs font-bold border-teal-500 border-2'>
                &lt;
            </div>
        </button>
    </div>
    <div class='mt-10'>
        <div class='flow-root px-5 space-y-1'>
            <div *ngIf='!userIsSubmitter' class='flex'>
                <a [routerLink]="['/forms']" [routerLinkActive]="['active']" class='navbar-list-item '>
                    <img class='inline-block mr-2' src='/assets/form-icon.svg' />
                    {{ textValues.formsPageNavText }}
                </a>
            </div>
            <div class='flex'>
                <a [routerLink]="['/submissions']" [routerLinkActive]="['active']" class='navbar-list-item'>
                    <img class='inline-block mr-2' src='/assets/submission-icon.svg' />
                    {{ textValues.submissionsPageNavText }}
                </a>
            </div>
            <div *ngIf='!userIsSubmitter' class='flex'>
                <a href='https://www.inclusiveforms.com/knowledgebase' class='navbar-list-item' target="_blank">
                    <img class='inline-block mr-2' src='/assets/knowledge-base-icon.svg' />
                    {{ textValues.knowledgeBasePageNavText }}
                </a>
            </div>
            <div class='flex'>
                <a [routerLink]="['/account']" [routerLinkActive]="['active']" class='navbar-list-item'>
                    <img class='inline-block mr-2' src='/assets/account-icon.svg' />
                    {{ textValues.accountPageNavText }}
                </a>
            </div>
        </div>
    </div>
</div>
<div *ngIf='isCollapsed' class='flex-row w-16 h-screen bg-teal-500 z-30'>
    <div class='flex justify-center'>
        <img class='py-10' src='/assets/inclusive-docs-logo-icon.svg' />
    </div>
    <div *ngIf='!userIsSubmitter' class='flex justify-center items-center my-3'>
        <button class='bg-gray-100 rounded-lg p-3' (click)='this.createFormBtnClicked.emit($event)'>
            <img src='/assets/add-note.svg' />
        </button>
    </div>
    <div class='absolute left-13'>
        <button type='button' (click)='toggleNavbarCollapse()'>
            <div class='px-1 rounded-full bg-white text-gray-500 text-xs font-bold border-teal-500 border-2'>
                &gt;
            </div>
        </button>
    </div>
    <div class='space-y-1 mt-13'>
        <div *ngIf='!userIsSubmitter' class='mx-2'>
            <a [routerLink]="['/forms']" [routerLinkActive]="['active']"
                class='flex justify-center items-center p-3.5 rounded-lg hover:bg-teal-600'>
                <img src='/assets/form-icon.svg' />
            </a>
        </div>
        <div class='mx-2'>
            <a [routerLink]="['/submissions']" [routerLinkActive]="['active']"
                class='flex justify-center items-center p-3.5 rounded-lg hover:bg-teal-600'>
                <img src='/assets/submission-icon.svg' />
            </a>
        </div>
        <div *ngIf='!userIsSubmitter' class='mx-2'>
            <a href='https://www.inclusiveforms.com/knowledgebase' target="_blank"
                class='flex justify-center items-center p-3.5 rounded-lg hover:bg-teal-600'>
                <img src='/assets/knowledge-base-icon.svg' />
            </a>
        </div>
        <div class='mx-2'>
            <a [routerLink]="['/account']" [routerLinkActive]="['active']"
                class='flex justify-center items-center p-3.5 rounded-lg hover:bg-teal-600'>
                <img src='/assets/account-icon.svg' />
            </a>
        </div>
    </div>
</div>