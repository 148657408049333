<button *ngIf="shouldShowHomeBtn" type="button" class="btn btn-outline-dark" style="margin: 20px;" [routerLink]="['/submissions']">HOME</button>
<div *ngIf="completedSubmission" style="margin-left: 20px;">
    <p>Thank you for submitting the <i>{{completedSubmission.Form.Name}}</i></p>
    <p>Submission ID: <b>{{completedSubmission.id}}</b>
    <br>
    Submission date and time: <b>{{completedSubmission.LastSubmissionDate | date: 'yyyy-MM-dd HH:mm'}}</b></p>
    <p>Please keep the submission ID, noted above, in your records.</p>
    <p *ngIf="completedSubmission.Form.AllowExportFormToPdf">To save a copy of your submission in PDF form click here: <button type="button" class="btn btn-outline-dark" (click)="saveSurveyToPdf()">Save as PDF</button></p>
    <p>If you wish to provide additional information to supplement this submission, 
        please complete the <a href="#">Supplemental Submission Form</a>.</p>
</div>