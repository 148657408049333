import gql from 'graphql-tag';

export const GET_ALL_EDITING_GROUPS_NAMES = gql`
    query {
        editingGroups {
            id,
            Name
        }
    }
`;
