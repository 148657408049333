<div class='flex h-screen'>
    <info-modal *ngIf='resetPasswordConfirmationShown' [header]='textValues.resetEmailModalHeader' [content]='userEmail' (closeModal)='closeConfirmationModal()'></info-modal>
    <div class='w-full'>
        <img class='h-full p-28' src='/assets/inclusive-docs-logo-black.svg' />
    </div>
    <div class='w-124 px-9 2xl:px-20 bg-teal-500'>
        <div class='relative top-20'>
            <div class='flex absolute space-x-10 2xl:space-x-20 items-end'>
                <h1 class='text-5xl font-bold text-gray-100 font-brand'>
                    {{ textValues.forgotPasswordHeader }}
                </h1>
            </div>
            <form #forgotPasswordForm='ngForm' (ngSubmit)='requestLinkForPasswordReset(forgotPasswordForm)' class='block relative top-36 space-y-7'>
                <div class='input-wrapper'>
                    <img class='mt-2' src='/assets/e-mail-teal.svg' />
                    <input type='email' name='email' ngModel required email placeholder='{{ textValues.forgotPasswordEmailPlaceholder }}' class='input-field' />
                </div>
                <div class='flex relative space-x-4 justify-end'>
                    <button type='submit' [disabled]='!forgotPasswordForm.form.valid'
                        class='h-10 w-56 text-teal-500 text-sm font-bold bg-white rounded'>
                        {{ textValues.resetEmailBtnTxt }}
                    </button>
                    <button type='button' (click)='goBack()' class='h-10 w-16 text-gray-700 text-sm font-bold bg-white rounded'>
                        {{ textValues.backBtnTxt }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
