import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Survey from 'survey-angular';
import { SubmissionModel } from '../../models/submission.model';
import { questionTypes } from 'src/app/shared/constants';
import { SubmissionService } from 'src/app/services/submission.service';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})
export class SurveyComponent implements OnInit {
  survey: Survey.Model;
  savingSurveyAnswers = false;
  
  onUploadFiles = new EventEmitter<any>();
  onSaveSurveyAnswers = new EventEmitter<any>();

  @Input() submission: SubmissionModel;

  constructor(private submissionService: SubmissionService, private router: Router) { }

  ngOnInit(): void {
    this.survey = new Survey.Model(this.submission.Form.Definition);
    this.handleSubmission(this.submission);
  }

  private handleSubmission(submission: SubmissionModel): void {
    if (submission.canResumeDraft) {
      this.restoreAnswers();
      this.restoreLastPage();
    } else if (submission.cannotRestoreDraft) {
      alert('Your submission results are not allowed for restorarion.\nYou must start over.');
      this.survey.clear(true);
    } else if (submission.canPreview) {
      this.restoreAnswers();
      this.openDisplayMode();
    } else if (!submission.canResumeOldVersionDraft) {
      alert('You are previewing a submission that is related to an older version of the Form.');
      this.restoreAnswers();
      this.openDisplayMode();
    }
    this.initSurveyModel();
  }

  private restoreAnswers(): void {
    if (this.submission.Answers) {
      this.survey.data = this.submission.Answers;
    }
  }

  private restoreLastPage(): void {
    this.survey.currentPageNo = this.submission.LastPageSubmitted;
  }

  private openDisplayMode(): void {
    this.survey.clear(false);
    this.survey.mode = 'display';
  }

  private initSurveyModel(): void {
    this.setRequiredSurveyProperties();
    this.attachToSurveyEvents();
    Survey.StylesManager.applyTheme('orange');
    Survey.SurveyNG.render('survey', { model: this.survey });
  }

  private setRequiredSurveyProperties(): void {
    this.survey.showCompletedPage = false;
    this.survey.sendResultOnPageNext = true;
    this.survey.navigateToUrl = null;
  }

  private attachToSurveyEvents(): void {
    this.survey.onPartialSend.add(sender => {
      this.submission.LastPageSubmitted = sender.currentPageNo;
      this.saveSurveyAnswers(sender);
    });

    this.survey.onComplete.add(sender => {
      this.savingSurveyAnswers = true;
      this.submission.IsDraft = false;
      this.saveSurveyAnswers(sender);
    });
    
    this.survey.onUploadFiles.add((sender, options) => {
      options.files.forEach(userFile => {
        this.onUploadFiles.next(userFile);

        this.submissionService.uploadFileToS3(userFile).subscribe(
          userFileS3Url => options.callback('success', [
            {
              file: userFile,
              content: userFileS3Url
            }
          ]),
          error => console.log('AWS.S3.getSignedUrl error: ', error)
        );
      });
    });

    this.survey
      .onAfterRenderQuestion
      .add((sender, options) => {
        if (options.question.getType() === questionTypes.file) {
          const aTags = options.htmlElement.getElementsByTagName('a');
          for (const aTag of aTags) {
            aTag.setAttribute('target', '_blank');
          }
        }
      });
  }

  private saveSurveyAnswers(survey: Survey.SurveyModel): void {
    this.submission.Answers = survey.data as JSON;
    this.onSaveSurveyAnswers.next(survey);

    this.submissionService.updateSubmission(this.submission.id, this.submission).subscribe(sub => {
      if (!sub.IsDraft) {
        this.submissionService.completeSubmission(this.submission.id, sub).subscribe(() => {
          this.savingSurveyAnswers = false;
          this.router.navigate([`/submissions/${this.submission.id}/thank-you`]);
        });
      }
    });
  }
}
