import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import * as SurveyCreator from 'survey-creator';

@Component({
  selector: 'app-survey-creator',
  templateUrl: './survey-creator.component.html',
  styleUrls: ['./survey-creator.component.css']
})
export class SurveyCreatorComponent implements OnInit, OnChanges {
  @Input() formJSON: JSON;
  @Output() saveFormTemplate: EventEmitter<JSON> = new EventEmitter<JSON>();
  editor: SurveyCreator.SurveyEditor;

  ngOnInit(): void {
    const editorOptions = {
      showEmbededSurveyTab: true,
      showLogicTab: true,
      showTranslationTab: true
    };

    this.applyCustomTheme();
    this.editor = new SurveyCreator.SurveyEditor('surveyElement', editorOptions);

    this.editor.saveSurveyFunc = () => {
      this.saveFormTemplate.emit(this.editor.JSON);
    };

    this.editor
        .onShowingProperty
        .add((sender, options) => {
          const propertiesToRemove = ['navigateToUrl', 'navigateToUrlOnCondition'];
          
          if (propertiesToRemove.includes(options.property.name)) {
            options.canShow = false;
      }
    });

    this.editor.showToolbox = 'right';
    this.editor.showPropertyGrid = 'right';
    this.editor.rightContainerActiveItem('toolbox');
    this.editor.JSON = this.formJSON;
    this.editor.showToolbox = 'right';
    this.editor.showPropertyGrid = 'right';
    this.editor.rightContainerActiveItem('toolbox');
    this.editor.haveCommercialLicense = true;
  }

  ngOnChanges(): void {
    if (this.editor) {
      this.editor.JSON = this.formJSON;
    }
  }

  private applyCustomTheme(): void {
    const teal500 = '#476C6C';
    const grey100 = '#F5F5F5';
    const grey200 = '#E5E5E5';
    const grey400 = '#A3A3A3';
    const grey600 = '#57534E';
    const grey700 = '#404040';
    const defaultThemeColorsEditor = SurveyCreator.StylesManager.ThemeColors['default'];

    defaultThemeColorsEditor['$primary-color'] = teal500;
    defaultThemeColorsEditor['$secondary-color'] = grey700;
    defaultThemeColorsEditor['$primary-text-color'] = grey600;
    defaultThemeColorsEditor['$secondary-text-color'] = grey400;
    defaultThemeColorsEditor['$primary-hover-color'] = teal500;
    defaultThemeColorsEditor['$selection-border-color'] = grey700;
    defaultThemeColorsEditor['$primary-icon-color'] = teal500;
    defaultThemeColorsEditor['$primary-bg-color'] = grey100;
    defaultThemeColorsEditor['$secondary-bg-color'] = grey100;
    defaultThemeColorsEditor['$primary-border-color'] = grey200;
    defaultThemeColorsEditor['$secondary-border-color'] = grey100;

    SurveyCreator.StylesManager.applyTheme();
  }
}
