<div #modalBackdrop class='modal-backdrop'>
    <div #shareForm class='fixed w-140 h-80 bg-white mx-auto shadow-modal rounded-md'>
        <div class='h-20 bg-teal-500 rounded-md'>
            <div class='text-gray-100 text-4xl font-brand font-bold leading-10 absolute left-44 top-5'>
                {{ textValues.shareFormModalHeader }}
            </div>
        </div>
        <div
            class='flex items-center justify-between w-124 px-2 bg-white border border-gray-100 rounded space-x-4 h-20 absolute top-32 left-8'>
            <img src='/assets/share-icon.svg' class='order-first mt-2' />
            <input type='text' #formShareLink [(ngModel)]='shareLink' readonly class='w-full input-field' />
        </div>
        <div class='flex justify-end space-x-4 mt-40 mr-7'>
            <button type='submit' class='px-6 py-3 text-sm font-bold leading-4 text-white whitespace-nowrap teal-button'
                (click)='copyText(formShareLink)'>
                {{ textValues.shareFormCopyBtnTxt }}
            </button>
            <button type='button'
                class='px-6 py-3 text-sm font-bold leading-4 text-white whitespace-nowrap bg-gray-700 rounded-md shadow-grey-button'
                (click)='closeBtnClicked.emit()'>
                {{ textValues.closeModalBtnText }}
            </button>
        </div>
    </div>
</div>