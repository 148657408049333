import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { FormModel } from '../models/form.model';
import { format, isValid } from 'date-fns';
import * as _ from 'lodash';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(values: any[], searchInputValue: string, properties: string[]): any[]{

    function isContaining(value: any): boolean{
      if (searchInputValue == '')
        return true;
      
      
      for (const prop of properties) {
        const val = _.get(value, prop);
        if (val == null)
          continue;
        


        try {
          let dateCheck = format(new Date(val), 'MM.dd.yy');

          if (dateCheck.toString().includes(searchInputValue.toLowerCase()))
            return true;
        } catch {

          if (val.toString().toLowerCase().includes(searchInputValue.toLowerCase()))
          return true;
        }
      
      }
      
      return false;
    }
    
    return values.filter(value => isContaining(value));
  }
}
