import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserModel } from '../models/user.model';
import { UserRoleEnum } from '../enums/user-role';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ModeratorOnlyGuard implements CanActivate {
  
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const loggedInUser = this.authService.currentUser.user;

    if (this.isModerator(loggedInUser) || this.isAdmin(loggedInUser)) {
      return true;
    }
    this.router.navigate(['/submissions']);
    return false;
  }

  private isModerator(user: UserModel): boolean {
    return user.role.name === UserRoleEnum.FormModerator;
  }

  private isAdmin(user: UserModel): boolean {
    return user.role.name === UserRoleEnum.Admin;
  }
}
