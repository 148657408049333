import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.css']
})
export class InfoModalComponent {
  @Input() header: string;
  @Input() content: string;

  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

}
