import { Pipe, PipeTransform } from '@angular/core';
import { ExpirationStatus } from '../enums/expiration-status';

@Pipe({
  name: 'messageExpired'
})
export class MessageExpiredPipe implements PipeTransform {

  transform(status: ExpirationStatus, daysLeft?: number): string {
    switch (status) {
      case ExpirationStatus.Expired:
        return 'Expired';
      case ExpirationStatus.ExpiresToday:
        return 'Expires today';
      case ExpirationStatus.NoExpireLimit:
        return 'Draft';
      case ExpirationStatus.Submitted:
        return 'Submitted';
      case ExpirationStatus.Expiring:
        return `Draft - Expires in ${daysLeft.toString()} days`;
    }
  }
}
