import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UserRoleEnum } from '../../enums/user-role';
import { AuthService } from 'src/app/services/auth.service';
import { TextValues } from '../../shared/text-values';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  @Output() createFormBtnClicked: EventEmitter<any> = new EventEmitter();
  userIsSubmitter: boolean;
  isCollapsed: boolean;

  constructor(private authService: AuthService, private layoutService: LayoutService, public textValues: TextValues) { }

  ngOnInit(): void {
    this.userIsSubmitter = this.authService.currentUser.user.role.name === UserRoleEnum.Submitter;
    this.isCollapsed = this.layoutService.navbarCollapsed;
  }

  toggleNavbarCollapse(): void {
    this.layoutService.navbarCollapsed = !this.layoutService.navbarCollapsed;
    this.isCollapsed = this.layoutService.navbarCollapsed;
  }

}
