import { Component, OnInit } from '@angular/core';
import { SubmissionService } from 'src/app/services/submission.service';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { SurveyModel, QuestionTextModel } from 'survey-core';
import * as SurveyAnalytics from 'survey-analytics';
import { DataTables } from 'survey-analytics/survey.analytics.datatables.js';
import { SubmissionModel } from 'src/app/models/submission.model';
import { Observable } from 'rxjs';
import { FormModel } from 'src/app/models/form.model';
import { DisplayMode } from 'src/app/enums/display-mode';
import { TextValues } from 'src/app/shared/text-values';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
  selector: 'app-survey-analytics',
  templateUrl: './survey-analytics.component.html',
  styleUrls: ['./survey-analytics.component.css']
})
export class SurveyAnalyticsComponent implements OnInit {
  survey: SurveyModel;
  hasSubmissions = false;
  formName: string;
  loadingAnalytics = true;
  createFormModalOpened = false;

  constructor(private route: ActivatedRoute, private submissionService: SubmissionService, public layoutService: LayoutService, public textValues: TextValues) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(paramMap => {
      const formIdentifier = paramMap.get('formIdentifier');
      const routePath = this.route.routeConfig.path;

      if (routePath.endsWith('datatable')) {
        this.generateSurveyAnalytics(formIdentifier, DisplayMode.Datatable);
      } else if (routePath.endsWith('diagrams')) {
        this.generateSurveyAnalytics(formIdentifier, DisplayMode.Diagrams);
      }
    });
  }

  openCreateFormModal(): void {
    this.createFormModalOpened = true;
  }

  closeCreateFormModal(): void {
    this.createFormModalOpened = false;
  }

  print(): void {
    window.print();
  }

  private generateSurveyAnalytics(formIdentifier: string, displayMode: string): void {
    this.getFormAndSubmissions(formIdentifier).subscribe(data => {
      if (data.submissions.length > 0) {

        this.hasSubmissions = true;
        this.formName = data.form.Name;

        this.survey = this.createSurveyModel(data.form);
        this.addQuestionForSubmitter(this.survey);

        const answers = data.submissions.map(sub => this.addAnswerForSubmitter(sub).Answers);
        const normalizedData = this.handleQuestionsWithoutAnswer(answers);

        if (displayMode === DisplayMode.Datatable) {
          this.generateDataTable(normalizedData);
        }
        if (displayMode === DisplayMode.Diagrams) {
          this.generateDiagrams(normalizedData);
        }
      }
      this.loadingAnalytics = false;
    });
  }

  private getFormAndSubmissions(formIdentifier: string): Observable<{ form: FormModel, submissions: SubmissionModel[] }> {
    return this.submissionService.getCompletedSubmissionsByForm(formIdentifier).pipe(
      map(subs => (
        { form: subs[0]?.Form, submissions: subs }
      ))
    );
  }

  private createSurveyModel(form: FormModel): SurveyModel {
    const formDefinition = form.Definition;
    return new SurveyModel(formDefinition);
  }

  private addQuestionForSubmitter(originalSurvey: SurveyModel): void {
    const submitterQuestion = new QuestionTextModel('submitter_name');
    submitterQuestion.visible = false;
    submitterQuestion.title = 'Submitter';
    originalSurvey.pages[0].addQuestion(submitterQuestion, 0);
  }

  private addAnswerForSubmitter(sub: SubmissionModel): SubmissionModel {
    if (!sub.Answers) {
      sub.Answers = JSON;
    }
    sub.Answers['submitter_name'] = sub.Submitter?.username || 'anonymous';
    return sub;
  }

  private handleQuestionsWithoutAnswer(answers: JSON[]): JSON[] {
    return answers.map(answer => {
      this.survey.getAllQuestions().forEach(q => {
        if (typeof answer[q.name] === 'undefined') {
          answer[q.name] = '';
        }
      });
      return answer;
    });
  }

  private generateDataTable(normalizedData: JSON[]) {

    DataTables.initJQuery((window as any)['jQuery']);
    const dataTable = new DataTables(this.survey as any, normalizedData, null);
    dataTable.render(document.getElementById('surveyAnalyticsDomNode'));
  }

  private generateDiagrams(normalizedData: JSON[]) {
    const visPanel = new SurveyAnalytics.VisualizationPanel(
      this.survey.getAllQuestions(),
      normalizedData,
      {
        allowDynamicLayout: true,
        allowHideQuestions: false
      }
    );
    visPanel.render(document.getElementById('surveyAnalyticsDomNode'));
  }
}
