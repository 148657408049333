import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import Utils from 'src/app/shared/utils';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  code: string;

  constructor(private route: ActivatedRoute, private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      // tslint:disable-next-line: no-string-literal
      this.code = params['code'];
    });
  }

  resetPassword(form: NgForm) {
    const newPass = form.value.password;
    const confirmPass = form.value.confirmPassword;

    this.authService.resetPassword(this.code, newPass, confirmPass).subscribe(
      _ => this.router.navigate(['/login']),
      error => alert(Utils.extractErrorMessage(error.errorResponse))
    );
  }
}
