import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserRoleEnum } from '../enums/user-role';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): 
              Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currentUser = this.authService.currentUser;
    if (currentUser) {
      const userIsAdminOrFormModerator = currentUser.user.role.name == UserRoleEnum.Admin || 
                                         currentUser.user.role.name == UserRoleEnum.FormModerator;
      const url = userIsAdminOrFormModerator ? '/forms' : '/submissions';
      this.router.navigate([url]);
      return false;
    }
    return true;
  }
}
