import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import * as _ from "lodash";

import { SubmissionModel } from 'src/app/models/submission.model';
import { SortDirection } from "src/app/enums/sort-direction";
import { ExpirationStatus } from 'src/app/enums/expiration-status';
import { SearchbarComponent } from '../searchbar/searchbar.component';
import { LayoutService } from 'src/app/services/layout.service';
import { SubmissionService } from 'src/app/services/submission.service';
import { TextValues } from 'src/app/shared/text-values';
import { Config } from 'src/app/shared/config';

@Component({
  selector: "app-submission-list",
  templateUrl: "./submission-list.component.html",
  styleUrls: ["./submission-list.component.css"],
})
export class SubmissionListComponent implements OnInit {
  submissions: SubmissionModel[];
  currentPage: number;
  createFormModalOpened = false;
  itemsPerPage = this.config.tenItems;
  sortDirection: string = SortDirection.Normal;
  searchText: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private submissionService: SubmissionService,
    private title: Title,
    public layoutService: LayoutService,
    public textValues: TextValues,
    public config: Config
  ) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.title.setTitle(this.textValues.submissionPageTitle);
            if (!params['page']) {
                this.navigateToPage(1);
            } else {
                this.currentPage = +params['page'];
            }
        });
        this.submissionService.getSubmissionsByUser().subscribe(response => {
            this.submissions = response.data.submissions.map(submission => new SubmissionModel(submission));
        });
    }

    onTextChange(text: string): void {
        this.searchText = text;
    }

    changeItemsPerPage(newNumber: number): void {
        this.itemsPerPage = newNumber;
    }

    navigateToPage(newPage: number): void {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { page: newPage.toString() },
            skipLocationChange: false
        });
    }


  sort(args: string[]): void {
    if (this.sortDirection === SortDirection.Normal) {
      this.submissions = _.orderBy(this.submissions, "LastSubmissionDate", SortDirection.Desc);
      this.sortDirection = SortDirection.Desc;
    } else if (this.sortDirection === SortDirection.Desc) {
      this.submissions = _.orderBy(this.submissions, args, this.sortDirection);
      this.sortDirection = SortDirection.Asc;
    } else if (this.sortDirection === SortDirection.Asc) {
      this.submissions = _.orderBy(this.submissions, args, this.sortDirection);
      this.sortDirection = SortDirection.Normal;
    }
  }

  calculateClasses(status: ExpirationStatus) {
    return {
      submitted: status === ExpirationStatus.Submitted,
      expired: status === ExpirationStatus.Expired,
      draft:
        status === ExpirationStatus.ExpiresToday ||
        status === ExpirationStatus.Expiring ||
        status === ExpirationStatus.NoExpireLimit,
    };
  }

  openCreateFormModal(): void {
    this.createFormModalOpened = true;
  }

  closeCreateFormModal(): void {
    this.createFormModalOpened = false;
  }
}
