declare var API_URL;
declare var WEB_URL;
declare var GRAPHQL_URL;

export const environment = {
  production: true,

  apiUrl: API_URL,
  webUrl: WEB_URL,
  graphQLUrl: GRAPHQL_URL,
};
