import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubmissionService } from 'src/app/services/submission.service';
import { AuthService } from 'src/app/services/auth.service';
import { SubmissionModel } from 'src/app/models/submission.model';
import * as SurveyPDF from 'survey-pdf';

@Component({
  selector: 'app-thank-you-page',
  templateUrl: './thank-you-page.component.html',
  styleUrls: ['./thank-you-page.component.css']
})

export class ThankYouPageComponent implements OnInit {
  completedSubmission: SubmissionModel;
  shouldShowHomeBtn: boolean;

  constructor(private route: ActivatedRoute, private submissionService: SubmissionService,
              private authService: AuthService) {
    this.shouldShowHomeBtn = this.authService.currentUser !== null;
  }

  ngOnInit() {
    this.route.paramMap.subscribe(map => {
      const completedSubmissionId = map.get('submissionId');
      this.submissionService
        .getSubmission(completedSubmissionId)
        .subscribe(sub => {
          if (sub) {
            this.completedSubmission = sub;
          }
        });
    });
  }

  saveSurveyToPdf(): void {
    const options = {
      fontSize: 14,
      margins: {
        top: 18
      },
      commercial: true
    };

    const surveyPDF = new SurveyPDF.SurveyPDF(this.completedSubmission.Form.Definition, options);

    surveyPDF.mode = 'display';
    surveyPDF.data = this.completedSubmission.Answers;

    surveyPDF.onRenderFooter.add((xsurvey, canvas) => {
      canvas.drawText({
        text: `${canvas.pageNumber}/${canvas.countPages}`,
        margins: {
          right: 12,
          bot: 12
        }
      });
    });

    surveyPDF.save();
  }
}
