import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NEVER, Observable, throwError, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import * as Survey from 'survey-angular';
import { AuthService } from '../../services/auth.service';
import { FormService } from '../../services/form.service';
import { SubmissionService } from '../../services/submission.service';
import { FormModel } from '../../models/form.model';
import { SubmissionModel } from '../../models/submission.model';
import { TextValues } from 'src/app/shared/text-values';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
  selector: 'app-submission',
  templateUrl: './submission.component.html',
  styleUrls: ['./submission.component.css']
})
export class SubmissionComponent implements OnInit {
  submission: SubmissionModel;
  survey: Survey.Model;
  userIsLoggedIn: boolean;
  savingSurveyAnswers = false;
  isInPreviewMode = false;
  createFormModalOpened = false;

  constructor(private router: Router, private route: ActivatedRoute,
              private authService: AuthService, private formService: FormService, private submissionService: SubmissionService, 
              public layoutService: LayoutService, public textValues: TextValues) { }

  ngOnInit(): void {
    this.userIsLoggedIn = this.authService.currentUser ? true : false;
    this.route.paramMap.subscribe(map => {
      const submissionId = map.get('submissionId');
      const formIdentifier = map.get('formIdentifier');
      if (submissionId) {
        this.submissionService.getSubmission(submissionId).subscribe(submission => {
          if (submission) {
            this.submission = submission;
          } else {
            alert('The submission doesn\'t exist or can\'t be shown!');
          }
        });
      } else {
        this.submissionService.getDraftSubmissionForUser(formIdentifier).subscribe(submission => {
          if (!submission || !submission.canResumeOldVersionDraft) {
            this.createNewSubmission(formIdentifier);
          } else {
            // TODO: good place to call 'handleSubmission(submission)' instead of navigating to itself.
            this.router.navigate([`submissions/${submission.id}`], { replaceUrl: true });
          }
        });
      }
    });
  }

  openCreateFormModal(): void {
    this.createFormModalOpened = true;
  }

  closeCreateFormModal(): void { 
    this.createFormModalOpened = false;
  }

  private createNewSubmission(formIdentifierParam: string): void {
    this.formService.getPublishedFormByFormIdentifier(formIdentifierParam)
      .pipe(
        concatMap(form => {
          if (!form) {
            alert('Failed to get the requested Form.\nIt is probably not published or the requested Form Identifier does not exist.');
            this.router.navigate([`forms`], { replaceUrl: true });
            return throwError(NEVER);
          }
          return of(form);
        }),
        concatMap(form => this.createNewSubmissionModel(form)))
      .subscribe(submission =>
        this.router.navigate([`submissions/${submission.id}`], { replaceUrl: true }));
  }

  private createNewSubmissionModel(form: FormModel): Observable<SubmissionModel> {
    const newSubmission = new SubmissionModel();
    newSubmission.Token = this.authService.userToken;
    newSubmission.Form = form;
    return this.submissionService.createSubmission(newSubmission);
  }

}
