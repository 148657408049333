import gql from 'graphql-tag';

export const SUBMISSION_INFO_IN_LIST = gql`
    fragment submissionInfo on Submission {
        id,
        IsDraft,
        FirstSubmissionDate,
        LastSubmissionDate,
        Form {
            Name,
            FormIdentifier,
            IsPublished,
            AllowDraftsSubmissionsRestoration,
            AllowsOlderVersionSubmissions,
            AllowMultipleSubmissions,
            DaysLimitForDraftSubmissions
        }
    }
`;
