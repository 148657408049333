import { Component, ElementRef, EventEmitter, HostListener, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { FormModel } from 'src/app/models/form.model';
import { FormService } from 'src/app/services/form.service';
import { TextValues } from '../../shared/text-values';
import Utils from 'src/app/shared/utils';

@Component({
  selector: 'app-create-form',
  templateUrl: './create-form.component.html',
  styleUrls: ['./create-form.component.css']
})
export class CreateFormComponent {
  @ViewChild('modalBackdrop') modalBackdrop: ElementRef;
  @ViewChild('createFormForm') createFormElem: ElementRef;
  @Output() closeBtnClicked: EventEmitter<any> = new EventEmitter<any>();

  newForm = new FormModel();

  constructor(private formService: FormService, private router: Router, public textValues: TextValues) { }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const targetElement = event.target as Element;
    if (targetElement === this.modalBackdrop.nativeElement) {
      this.closeBtnClicked.emit();
    }
  }

  createForm(f: NgForm): void {
    this.newForm.Name = f.value.formName;
    this.formService.createForm(this.newForm).subscribe(createdForm => {
      this.router.navigate([`/forms/${createdForm.FormIdentifier}/versions/${createdForm.FormVersion}`]);
    });
  }
}
