import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserRoleEnum } from 'src/app/enums/user-role';
import { formListPage, defaultPage, submissionListPage } from 'src/app/shared/constants';
import { TextValues } from 'src/app/shared/text-values';
import { Title } from '@angular/platform-browser';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    userRole: string;
    requestedUrl: string;

    constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute, private title: Title, public textValues: TextValues) { }

    ngOnInit() {
        this.title.setTitle(this.textValues.loginPageTitle);
        this.route.queryParamMap.subscribe(paramMap => this.requestedUrl = paramMap.get('requestedUrl'));
    }

    login(form: NgForm): void {
        this.authService.userLogin(form.value).subscribe({
            next: () => {
                this.userRole = this.authService.currentUser?.user.role.name;
                const nextUrlForLoggedUser = this.calculateNextUrl(this.requestedUrl, this.userRole);
                this.router.navigate([nextUrlForLoggedUser]);
            },
            error: () => alert('Invalid email or password')
        });
    }
    calculateNextUrl(requestedUrl: string, userRole: string): string {
        if (requestedUrl && requestedUrl !== formListPage) {
            return requestedUrl;
        }
        if (userRole) {
            switch (userRole) {
                case UserRoleEnum.Admin:
                case UserRoleEnum.Public:
                    return defaultPage;
                case UserRoleEnum.FormModerator:
                    return formListPage;
                case UserRoleEnum.Submitter:
                    return submissionListPage;
            }
        }
    }
}
