<div #modalBackdrop class='modal-backdrop'>
    <div #formSettings class='fixed w-208 h-96 bg-white shadow-modal rounded-md'>
        <div class='flex h-20 bg-teal-500 rounded-md items-center'>
            <div *ngIf='form' class='text-gray-100 text-4xl font-brand font-bold mx-auto'>
                {{ form.Name }} Settings
            </div>
            <div *ngIf='!form' class='text-gray-100 text-4xl font-brand font-bold mx-auto'>
                {{ textValues.loadingLabel }}
            </div>
        </div>
        <div class='flex relative top-6 w-11/12 inset-x-16 m-0 items-center'>
            <app-spinner *ngIf='!form' class='relative ml-72 my-10'></app-spinner>
            <ng-container *ngIf='form && !simpleDisplay'>
                <div class='w-11/12'>
                    <div class='flex items-center leading-8'>
                        <input [(ngModel)]='form.AllowAnonymousSubmission' type='checkbox' id='anonymousSubmissionInp'
                            class='input-checkbox' />
                        <label class="ml-3" for='anonymousSubmissionInp'>
                            {{ textValues.formSettingsAnonymousSubmissionLbl }}
                        </label>
                    </div>
                    <div class='flex items-center leading-8'>
                        <input [(ngModel)]='form.AllowSubmitResultsToEmail' type='checkbox' id='submitResultsEmailInp'
                            class='input-checkbox' />
                        <label class="ml-3" for='submitResultsEmailInp'>
                            {{ textValues.formSettingsSubmitResultsEmailLbl }}
                        </label>
                    </div>
                    <div class='flex items-center leading-8'>
                        <input [(ngModel)]='form.AllowMultipleSubmissions' type='checkbox' id='multipleSubmissionsInp'
                            class='input-checkbox' />
                        <label class="ml-3" for='multipleSubmissionsInp'>
                            {{ textValues.formSettingsMultipleSubmissionsLbl }}
                        </label>
                    </div>
                    <div class='flex items-center leading-8'>
                        <input [(ngModel)]='form.AllowExportFormToPdf' type='checkbox' id='exportFormPdfInp'
                            class='input-checkbox' />
                        <label class="ml-3" for='exportFormPdfInp'>
                            {{ textValues.formSettingsExportFormPdfLbl }}
                        </label>
                    </div>
                    <div class='flex items-center leading-8'>
                        <input [(ngModel)]='form.AllowDraftsSubmissionsRestoration' type='checkbox'
                            id='draftSubmissionRestorationInp' class='input-checkbox' />
                        <label class="ml-3" for='draftSubmissionRestorationInp'>
                            {{ textValues.formSettingsDraftSubmissionRestorationLbl }}
                        </label>
                    </div>
                    <div class='flex items-center leading-8'>
                        <input [(ngModel)]='form.AllowsOlderVersionSubmissions' type='checkbox'
                            id='olderVersionSubmissionInp' class='input-checkbox' />
                        <label class="ml-3" for='olderVersionSubmissionInp'>
                            {{ textValues.formSettingsOlderVersionSubmission }}
                        </label>
                    </div>
                </div>
                <div class='w-11/12 leading-10'>
                    <div class='flex space-x-3'>
                        <label>
                            {{ textValues.formSettingsPublishedStatusLbl }}
                        </label>
                        <app-toggle [isChecked]='form.IsPublished' (onChange)='publishFormToggleChanged()'></app-toggle>
                    </div>
                    <div class='space-x-3'>
                        <label for='formVersionInp'>{{ textValues.formSettingsFormVersionLbl }}:</label>
                        <input id='formVersionInp' readonly [(ngModel)]='form.FormVersion' type='text'
                            class='w-12 text-center input-text' />
                    </div>
                    <div class='space-x-4'>
                        <label for='draftSubmissionLimitInp'>{{ textValues.formSettingsDaysLeftDraftSubmission
                            }}:</label>
                        <input id='draftSubmissionLimitInp' [(ngModel)]='form.DaysLimitForDraftSubmissions'
                            type='number' class='w-12 text-center input-text' />
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf='form && simpleDisplay'>
                <div class='w-11/12'>
                    <div class='flex items-center leading-8'>
                        <input [(ngModel)]='form.AllowAnonymousSubmission' type='checkbox' id='anonymousSubmissionInp'
                            class='input-checkbox' />
                        <label class="ml-3" for='anonymousSubmissionInp'>
                            {{ textValues.formSettingsAnonymousSubmissionLbl }}
                        </label>
                    </div>
                    <div class='flex items-center leading-8'>
                        <input [(ngModel)]='form.AllowSubmitResultsToEmail' type='checkbox' id='submitResultsEmailInp'
                            class='input-checkbox' />
                        <label class="ml-3" for='submitResultsEmailInp'>
                            {{ textValues.formSettingsSubmitResultsEmailLbl }}
                        </label>
                    </div>
                    <div class='flex items-center leading-8'>
                        <input [(ngModel)]='form.AllowMultipleSubmissions' type='checkbox' id='multipleSubmissionsInp'
                            class='input-checkbox' />
                        <label class="ml-3" for='multipleSubmissionsInp'>
                            {{ textValues.formSettingsMultipleSubmissionsLbl }}
                        </label>
                    </div>
                    <div>
                        <label class='block pt-4 pb-2' for='editingGroupsDropdown'>{{
                            textValues.formSettingsEditingGroupsLabel }}:</label>
                        <div class="block w-24 text-left">
                            <button type='button'
                                class='inline-flex w-full items-center rounded border-solid border-2 pl-1'>
                                Select
                                <img class='-mr-1 ml-2 h-5 w-5' src='/assets/down-arrow.svg' />
                            </button>
                        </div>
                    </div>
                </div>
                <div class='w-11/12 leading-10'>
                    <div class='flex items-center leading-8'>
                        <input [(ngModel)]='form.AllowExportFormToPdf' type='checkbox' id='exportFormPdfInp'
                            class='input-checkbox' />
                        <label class="ml-3" for='exportFormPdfInp'>
                            {{ textValues.formSettingsExportFormPdfLbl }}
                        </label>
                    </div>
                    <div class='flex items-center leading-8'>
                        <input [(ngModel)]='form.AllowDraftsSubmissionsRestoration' type='checkbox'
                            id='draftSubmissionRestorationInp' class='input-checkbox' />
                        <label class="ml-3" for='draftSubmissionRestorationInp'>
                            {{ textValues.formSettingsDraftSubmissionRestorationLbl }}
                        </label>
                    </div>
                    <div class='flex items-center leading-8'>
                        <input [(ngModel)]='form.AllowsOlderVersionSubmissions' type='checkbox'
                            id='olderVersionSubmissionInp' class='input-checkbox' />
                        <label class="ml-3" for='olderVersionSubmissionInp'>
                            {{ textValues.formSettingsOlderVersionSubmission }}
                        </label>
                    </div>
                    <div>
                        <label class='block' for='draftSubmissionLimitInp'>{{
                            textValues.formSettingsDaysLeftDraftSubmission }}:</label>
                        <input id='draftSubmissionLimitInp' [(ngModel)]='form.DaysLimitForDraftSubmissions' type='text'
                            class='input-text w-24 block pl-1' />
                    </div>
                </div>
            </ng-container>
        </div>
        <div class='relative flex top-10 justify-end'>
            <div class='relative space-x-4 right-5'>
                <button *ngIf="form" type='submit' (click)='saveChanges.emit(form)' class='modal-button teal-button'>
                    {{ textValues.saveChangesModalBtnText }}
                </button>
                <button type='button' (click)='closeModal.emit()'
                    class='modal-button bg-gray-700 rounded-md shadow-grey-button'>
                    {{ textValues.closeModalBtnText }}
                </button>
            </div>
        </div>
    </div>
</div>