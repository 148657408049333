import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgxPaginationModule } from 'ngx-pagination';
import { GraphQLModule } from './graphql.module';
import { AppRoutingModule } from './app-routing.module';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { MessageExpiredPipe } from './pipes/message-expired.pipe';
import { ToStringPipe } from './pipes/to-string.pipe';
import { EqualsToValidatorDirective } from './directives/equals-to-validator/equals-to-validator.directive';
import { AppComponent } from './app.component';
import { FormListComponent } from './components/form-list/form-list.component';
import { FormComponent } from './components/form/form.component';
import { SurveyCreatorComponent } from './components/survey-creator/survey-creator.component';
import { CreateFormComponent } from './components/create-form/create-form.component';
import { SubmissionComponent } from './components/submission/submission.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ThankYouPageComponent } from './components/thank-you-page/thank-you-page.component';
import { FormSettingsComponent } from './components/form-settings/form-settings.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { SubmissionListComponent } from './components/submission-list/submission-list.component';
import { AnswersComponent } from './components/answers/answers.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SurveyAnalyticsComponent } from './components/survey-analytics/survey-analytics.component';
import { SearchbarComponent } from './components/searchbar/searchbar.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ShareFormComponent } from './components/share-form/share-form.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { InfoModalComponent } from './components/info-modal/info-modal.component';
import { SurveyComponent } from './components/survey/survey.component';
import { UserProfileDropdownComponent } from './components/user-profile-dropdown/user-profile-dropdown.component';
import { SearchFilterPipe } from './pipes/search-filter.pipe';

@NgModule({
  declarations: [
    AppComponent,
    FormListComponent,
    FormComponent,
    SurveyCreatorComponent,
    CreateFormComponent,
    ToStringPipe,
    SubmissionComponent,
    LoginComponent,
    RegisterComponent,
    ThankYouPageComponent,
    FormSettingsComponent,
    PageHeaderComponent,
    SubmissionListComponent,
    AnswersComponent,
    SpinnerComponent,
    MessageExpiredPipe,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SurveyAnalyticsComponent,
    SearchbarComponent,
    NavbarComponent,
    ShareFormComponent,
    DropdownComponent,
    ToggleComponent,
    EqualsToValidatorDirective,
    InfoModalComponent,
    SurveyComponent,
    UserProfileDropdownComponent,
    SearchFilterPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    GraphQLModule,
    NgxPaginationModule,
  ],
  providers: [
    {
    provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
