import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { TextValues } from '../../shared/text-values';
import Utils from 'src/app/shared/utils';

@Component({
  selector: 'app-share-form',
  templateUrl: './share-form.component.html',
  styleUrls: ['./share-form.component.css']
})
export class ShareFormComponent {
  @ViewChild('modalBackdrop') modalBackdrop: ElementRef;
  @ViewChild('shareForm') shareFormElem: ElementRef;
  @Input() shareLink: string;
  @Output() closeBtnClicked: EventEmitter<any> = new EventEmitter();

  constructor(public textValues: TextValues) { }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const targetElement = event.target as Element;
    if (targetElement === this.modalBackdrop.nativeElement) {
      this.closeBtnClicked.emit();
    }
  }

  copyText(inputValue): void {
    inputValue.select();
    document.execCommand('copy');
  }
}
