<div class='flex h-screen'>
    <div class='w-full'>
        <img class='h-full p-28' src='/assets/inclusive-docs-logo-black.svg' />
    </div>
    <div class='w-124 px-9 2xl:px-20 bg-teal-500'>
        <div class='relative top-20'>
            <div class='flex absolute space-x-10 2xl:space-x-20 items-end'>
                <h1 class='text-5xl font-bold text-gray-100 font-brand'>
                    {{ textValues.loginLinkTxt }}
                </h1>
                <a class='text-3xl font-bold text-teal-300' [routerLink]="['/register']" href='#'>
                    {{textValues.registerLinkTxt}}
                </a>
            </div>
            <form #userLoginForm='ngForm' (ngSubmit)='login(userLoginForm)' class='block relative top-24 space-y-10'>
                <div class='input-wrapper'>
                    <img class='mt-2' src='/assets/e-mail-teal.svg' />
                    <input type='email' name='identifier' ngModel required placeholder='{{ textValues.loginEmailPlaceholder }}' class='input-field' />
                </div>
                <div class='input-wrapper'>
                    <img class='mt-2' src='/assets/lock-teal.svg' />
                    <input type='password' name='password' ngModel required placeholder='{{ textValues.loginPasswordPlaceholder }}' class='input-field' />
                </div>
                <div class='flex relative top-10 space-x-5 justify-end'>
                    <button type='submit' [disabled]='!userLoginForm.form.valid'
                        class='h-10 w-20 text-teal-500 font-bold bg-white rounded'>
                        {{ textValues.loginBtnTxt }}
                    </button>
                    <button class='h-10 w-40 text-gray-700 font-bold bg-white rounded' [routerLink]="['/forgot-password']">
                        {{ textValues.forgotPasswordBtnTxt }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
