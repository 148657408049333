import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { TextValues } from 'src/app/shared/text-values';

@Component({
  selector: 'user-profile-dropdown',
  templateUrl: './user-profile-dropdown.component.html',
  styleUrls: ['./user-profile-dropdown.component.css']
})
export class UserProfileDropdownComponent implements OnInit {
  username: string;
  userRole: string;
  opened = false;

  constructor(private authService: AuthService, public textValues: TextValues) { }

  ngOnInit(): void {
    this.username = this.authService.currentUser?.user.username;
    this.userRole = this.authService.currentUser?.user.role.name;
  }

  toggleDropdown(): void {
    this.opened = !this.opened;
  }

  logout(): void {
    this.authService.userLogout();
  }

}
