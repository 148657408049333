import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import Utils from '../../shared/utils';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent {
  @ViewChild('dropdown') dropdown: ElementRef;
  @Input() classes: string = "";
  @Input() openDirection = "left";

  opened = false;
  
  constructor() { }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const targetElement = event.target as Element;
    const dropdownContainer = targetElement.querySelector(".dropdown-container");
    const isInsideDropdown = Utils.elementIsInside(this.dropdown, targetElement);
    const isInsideItems = Utils.elementIsInside(this.dropdown, dropdownContainer);
    if (isInsideItems || !isInsideDropdown) {
      this.opened = false;
    }
  }
}
