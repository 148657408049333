import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { HttpBaseService } from './http-base.service';
import { IUserCredentials } from '../interfaces/user-credentials';
import { IAuthResponse } from '../interfaces/auth-response';
import { UserModel } from '../models/user.model';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private currentUserSubject: BehaviorSubject<IAuthResponse>;
    private userTokenSubject: BehaviorSubject<string>;
    private currentUserItem = 'currentUser';
    private userTokenItem = 'userToken';

    constructor(private http: HttpBaseService) {
        this.currentUserSubject = new BehaviorSubject<IAuthResponse>(JSON.parse(localStorage.getItem(this.currentUserItem)));
        this.userTokenSubject = new BehaviorSubject<string>(localStorage.getItem(this.userTokenItem));

        if (!localStorage.getItem(this.userTokenItem)) {
            this.createAndStoreNewToken();
        }
    }

    get currentUser(): IAuthResponse {
        return this.currentUserSubject.value;
    }

    get userToken(): string {
        return this.userTokenSubject.value;
    }

    userRegistration(userModel: UserModel): Observable<IAuthResponse> {
        return this.http.post<IAuthResponse>(`/auth/local/register`, userModel)
            .pipe(
                map(response => this.storeCurrentUser(response))
            );
    }

    userLogin(userCredentials: IUserCredentials): Observable<IAuthResponse> {
        return this.http.post<IAuthResponse>(`/auth/local`, userCredentials)
            .pipe(
                map(response => {
                    this.createAndStoreNewToken();
                    return this.storeCurrentUser(response);
                })
            );
    }

    userLogout(): void {
        localStorage.removeItem(this.userTokenItem);
        localStorage.removeItem(this.currentUserItem);
        this.currentUserSubject.next(null);
    }

    forgotPassword(email: string): any {
        return this.http.post<any>(`/auth/forgot-password`, { email });
    }

    resetPassword(code: string, password: string, passwordConfirmation: string): any {
        return this.http.post<any>(`/auth/reset-password`, { code, password, passwordConfirmation });
    }

    private createAndStoreNewToken(): string {
        const token = uuidv4();
        localStorage.setItem(this.userTokenItem, token);
        this.userTokenSubject.next(token);
        return token;
    }

    private storeCurrentUser(response: IAuthResponse): IAuthResponse {
        localStorage.setItem(this.currentUserItem, JSON.stringify(response));
        this.currentUserSubject.next(response);
        return response;
    }
}
