import { HttpErrorResponse } from '@angular/common/http';
import { ElementRef } from '@angular/core';
import { throwError } from 'rxjs';
import { HttpErrorMessage } from '../interfaces/http-error-message';

export default class Utils {
    static handleError(err: HttpErrorResponse) {
        const error: HttpErrorMessage = {
            errorMessage: '',
            statusCode: err.status,
            errorResponse: err
        };

        if (err.error instanceof ErrorEvent) {
            error.errorMessage = `An error occured: ${err.error.message}`;
        } else {
            error.errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }

        return throwError(error);
    }

    static extractErrorMessage(errorResponse: HttpErrorResponse): string {
        let errorMessage = '';
        errorResponse.error.data.forEach(d => d.messages.forEach(m => errorMessage = errorMessage.concat(m.message, '\n')));
        return errorMessage;
    }

    static elementIsInside(parentElement: ElementRef, element: Element): boolean {
        while (element && element !== document.body) {
            if (parentElement.nativeElement === element) return true;
            element = element.parentElement;
        }
        return false;
      }
}
