import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class Config {
    public tenItems = 10;
    public twentyFiveItems = 25;
    public fiftyItems = 50;
    public hundredItems = 100;
}
