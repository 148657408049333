import { Component, Input, Output, EventEmitter, HostListener, ElementRef, ViewChild } from '@angular/core';
import { FormModel } from 'src/app/models/form.model';
import { FormSettings } from 'src/app/interfaces/form-settings';
import { EditingGroupModel } from 'src/app/models/editing-group.model';
import { TextValues } from '../../shared/text-values';

@Component({
  selector: 'app-form-settings',
  templateUrl: './form-settings.component.html',
  styleUrls: ['./form-settings.component.css']
})
export class FormSettingsComponent {
  @ViewChild('modalBackdrop') modalBackdrop: ElementRef;
  @ViewChild('formSettings') formSettings: ElementRef;
  @Input() form: FormModel;
  @Input() simpleDisplay: boolean;
  @Output() saveChanges: EventEmitter<FormModel> = new EventEmitter<FormModel>();
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() addEditingGroup: EventEmitter<EditingGroupModel> = new EventEmitter<EditingGroupModel>();
  @Output() removeEditingGroup: EventEmitter<EditingGroupModel> = new EventEmitter<EditingGroupModel>();
  // @Input() allEditingGroups: EditingGroupModel[];
  // @Input() formEditingGroups: {[key: string]: boolean};

  settings: FormSettings[];

  constructor(public textValues: TextValues) { }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const targetElement = event.target as Element;
    if (targetElement === this.modalBackdrop.nativeElement) {
      this.closeModal.emit();
    }
  }

  publishFormToggleChanged(): void {
    this.form.IsPublished = !this.form.IsPublished;
  }
}
