import { FormModel } from './form.model';
import { UserModel } from './user.model';
import { ExpirationStatus } from '../enums/expiration-status';

export interface SubmissionJSON {
  id: string;
  Token: string;
  Answers: JSON;
  IsDraft: boolean;
  FirstSubmissionDate: Date;
  LastSubmissionDate: Date;
  Form: FormModel;
  LastPageSubmitted: number;
  Submitter: UserModel;
}

export class SubmissionModel implements SubmissionJSON {
  id: string;
  Token: string;
  Answers: JSON;
  IsDraft: boolean;
  FirstSubmissionDate: Date;
  LastSubmissionDate: Date;
  Form: FormModel;
  LastPageSubmitted: number;
  Submitter: UserModel;

  constructor(json: SubmissionJSON = null) {
    if (json) {
      Object.assign(this, json);
    }
  }

  get canResumeDraft(): boolean {
    return this.IsDraft
      && this.Form.AllowDraftsSubmissionsRestoration
      && this.canResumeOldVersionDraft
      && this.expirationStatus !== ExpirationStatus.Expired;
  }

  get canResumeOldVersionDraft(): boolean {
    return this.IsDraft
      && (this.Form.IsPublished || this.Form.AllowsOlderVersionSubmissions);
  }

  get cannotRestoreDraft(): boolean {
    return this.IsDraft && !this.Form.AllowDraftsSubmissionsRestoration;
  }

  get canPreview(): boolean {
    return this.expirationStatus === ExpirationStatus.Expired
      || this.expirationStatus === ExpirationStatus.Submitted;
  }

  get canSubmitNew(): boolean {
    return (this.expirationStatus === ExpirationStatus.Expired || this.expirationStatus === ExpirationStatus.Submitted)
      && this.Form.AllowMultipleSubmissions
      || !this.canResumeOldVersionDraft;
  }

  get expirationStatus(): ExpirationStatus {
    if (!this.IsDraft) {
      return ExpirationStatus.Submitted;
    } else if (!this.Form.DaysLimitForDraftSubmissions) {
      return ExpirationStatus.NoExpireLimit;
    }
    const daysLeft = this.daysLeftTillDraftExpire;
    if (daysLeft < 0) {
      return ExpirationStatus.Expired;
    } else if (daysLeft === 0) {
      return ExpirationStatus.ExpiresToday;
    }
    return ExpirationStatus.Expiring;
  }

  get daysLeftTillDraftExpire(): number {
    const dueDate = new Date();
    dueDate.setDate(new Date(this.FirstSubmissionDate).getUTCDate() + this.Form.DaysLimitForDraftSubmissions);
    const millisecondsLeft = dueDate.valueOf() - Date.now().valueOf();
    const daysLeft = Math.floor(millisecondsLeft / (1000 * 3600 * 24));
    return daysLeft;
  }
}
