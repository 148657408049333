import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { TextValues } from 'src/app/shared/text-values';
import Utils from 'src/app/shared/utils';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent {

  constructor(private authService: AuthService, private router: Router, public textValues: TextValues) {
    if (this.authService.currentUser) {
      this.router.navigate(['/']);
    }
  }

  register(form: NgForm): void {
    if (form.valid) {
      this.authService.userRegistration(form.value).subscribe(() => this.router.navigate(['/']), error => alert(Utils.extractErrorMessage(error.errorResponse)));
    }
  }
}
