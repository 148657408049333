import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { TextValues } from 'src/app/shared/text-values';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.css']
})
export class PageHeaderComponent implements OnInit {
  userIsLoggedIn: boolean;

  constructor(private authService: AuthService, public textValues: TextValues) { }

  ngOnInit() {
    this.userIsLoggedIn = this.authService.currentUser.user ? true : false;
  }
}
