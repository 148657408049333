import { headers } from '../../../../shared/constants';

interface ICustomHeaders {
    userToken: 'user-token';
}

export const sharedHeaders: ICustomHeaders = headers;

export const defaultPage = '/';
export const formListPage = '/forms';
export const submissionListPage = '/submissions';

export const questionTypes = {
    file: 'file'
};

Object.freeze(questionTypes);
