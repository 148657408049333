<div class='absolute w-140 h-72 bg-white mx-auto left-1/3 top-1/4 shadow-modal rounded-md'>
    <div class='flex h-1/4 items-center justify-center bg-teal-500 rounded-md'>
        <div class='text-gray-100 text-3xl font-brand font-bold leading-10'>
            {{ header }}
        </div>
    </div>
    <div class='flex h-2/4  items-center justify-center px-2'>
        <div class='text-gray-700 text-3xl font-brand font-bold'>
            {{ content }}
        </div>
    </div>
    <div class='flex justify-end space-x-4'>
        <button type='button' (click)='closeModal.emit()' class='px-6 py-3 mr-5 text-sm font-bold leading-4 text-white whitespace-nowrap bg-gray-700 shadow-grey-button rounded-md'>
            Close
        </button>
    </div>
</div>
