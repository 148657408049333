<div class='flex min-h-screen bg-gray-100'>
    <app-create-form *ngIf='createFormModalOpened' (closeBtnClicked)='closeCreateFormModal()'>SHOWN!</app-create-form>
    <navbar (createFormBtnClicked)='openCreateFormModal()' class="z-30"></navbar>
    <div [ngClass]="{'page-container-navbar-collapsed': layoutService.navbarCollapsed, 'page-container-navbar-not-collapsed': !layoutService.navbarCollapsed}" class='h-screen'>
        <app-page-header></app-page-header>
        <div class='flex px-10 my-3 items-center'>
            <div class='w-3/4 text-4xl font-bold leading-10 text-gray-700'>
                {{ formName }}
            </div>
            <div class='w-1/4 flex justify-end'>
                <button (click)="print()" class='back-button mr-4'>Print</button>
                <button [routerLink]="['/forms']" class='back-button'>
                    {{ textValues.backBtnTxt }}
                </button>
            </div>
        </div>
        <div *ngIf='loadingAnalytics' class='flex h-full justify-center'>
            <app-spinner class='flex self-center'></app-spinner>
        </div>
        <div class='flex m-5 overflow-x-scroll'>
            <div id="surveyAnalyticsDomNode" class="w-full"></div>
        </div>
    </div>
</div>